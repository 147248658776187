/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/indigo-pink.css";

/* Define the custom font-face */
/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */


@font-face {
    font-family: "Montserrat-Bold";
    src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Montserrat-Medium";
    src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Montserrat-Regular";
    src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Montserrat-Light";
    src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
  }
  
 

  :root {
  
    --font-1-font-family: "Montserrat-Bold";
    --font-2-font-family: "Montserrat-Medium";
    --font-3-font-family: "Montserrat-Regular";
    --font-4-font-family: "Montserrat-Light";
    --color-primary: #0d3b30;
    --color-secondary: #f07829;
    --color-white: #fff;
    --color-black: #000000;
    --color-gray: #6c757d;
    --color-light-blue: #344054;
    --color-purple: #53389E;
    --color-dark-red: #B42318;
    --color-cool-gray: #b1b1b3;
    --color-light-gray: #F9FAFB;
    --color-light-gray-1: #F7FAFF;
    --color-dark-gray: #e4e6ef;
    --color-blue: #0070ff;
    --color-light-blue: #00aff0;
    --color-skyblue: #7ec8e7;
    --color-orange: #FFA500;
    --color-teal: #008080;
    --color-darkgreen: #006400;
    --color-lightgreen: #4BB543;
    --color-steelblue: #4682B4;
    --color-golden-yellow: #ebad13;
    --color-lime-green: #e8f20f;
    --color-yellow-green: #c1f217;
    --link-color-normal: #6c757d;
    --link-color-hover: #575e94;
    --color-black-russian: #101828;
    --color-grayish-blue: #667085;
    --color-cyan-blue: #667085;
    --color-dark-grayish-blue: #475467;
    --color-dark-blue-gray: #0C111D;
    --color-dark-slate-blue: #344054;
    --color-Pumpkin: #fe7517;
    --color-main-gray: #F5F5F7;
    --color-vivid-green: #17b26a;
    --color-vibrant-red: #ff0000;
    --color-waiting: #1C93EB;
    --color-preparing: #FF803D;
    --color-completed: #122B38;
    --color-delivered: #49AC00;
    --color-cancel: #A70006;

  }
  
  html {
    font-size: 1rem;
  }
  
  body {
    font-family: var(--font-1-font-family);
    letter-spacing: 0.01rem;
    background: #F5F5F7;
  }
  
  ._login_page{
    height: 100vh;
    text-align: center;
    background-size: cover;
    background-position: 100% 100%;
    background-repeat: no-repeat;
  }
  
  
  .color-gray {
    color: var(--color-gray);
  }
  
  .color-white {
    color: var(--color-white);
  }
  
  .color-black {
    color: var(--color-black);
  }
  
  .color-light-blue {
    color: var(--color-light-blue);
  }
  
  .main-bg-color {
    background: var(--color-main-gray);
  }
  
  .line-height-normal {
    line-height: normal;
  }
  
 
  p {
    line-height: normal;
    font-size: 0.85rem;
    font-family: var(--font-3-font-family);
    margin: 0px;
  }
  
  span {
    font-weight: 400;
    line-height: normal;
  }
  
  .font-1 {
    font-family: var(--font-1-font-family);
  }
  
  .font-2 {
    font-family: var(--font-2-font-family);
  }
  
  .font-3 {
    font-family: var(--font-3-font-family);
  }
  
  .font-4 {
    font-family: var(--font-4-font-family);
  }
  
  .font-5 {
    font-family: var(--font-5-font-family);
  }
  
  .display-10 {
    font-size: 10px;
  }
  
  .display-12 {
    font-size: 12px;
  }

  .display-14 {
    font-size: 14px;
  }

  .display-16 {
    font-size: 16px;
  }

  .display-18 {
    font-size: 18px;
  }
  
  .display-20 {
    font-size: 20px;
  }
 
  .display-22 {
    font-size: 22px;
  }

  .display-24 {
    font-size: 24px;
  }

  .display-26 {
    font-size: 26px;
  }

  .display-28 {
    font-size: 28px;
  }

  .display-30 {
    font-size: 30px;
  }

  .display-32 {
    font-size: 32px;
  }
  
  .display-34 {
    font-size: 34px;
  }

  .display-36 {
    font-size: 36px;
  }

  .display-table-rows{
    font-size: 12px;
  }

  .w-normal {
    font-weight: 400;
  }
  
  .w-regular {
    font-weight: 500;
  }
  
  .w-bold {
    font-weight: 600;
  }
  
  
  .mandatory {
    color: var(--color-dark-red);
  }
  
  .text-underline {
    text-decoration: underline;
  }
  

  /* status background */
  .bg-waiting {
    background-color: var(--color-waiting);
  }
  
  .bg-preparing {
    background-color: var( --color-preparing);
  }
  
  .bg-completed {
    background-color: var(--color-completed);
  }
  
  .bg-delivered {
    background-color: var( --color-delivered);
  }
  
  .bg-cancel {
    background-color: var(--color-cancel);
  }
  
  .bg-gray {
    background-color: var(--color-light-gray-1);
  }

  .bg-red {
    background-color: var(--color-vibrant-red);
  }

  .bg-green {
    background-color: var(--color-darkgreen);
  }
  
  /* status color */
  .color-waiting {
    color: var(--color-waiting);
  }
  
  .color-preparing {
    color: var( --color-preparing);
  }
  
  .color-completed {
    color: var(--color-completed);
  }
  
  .color-delivered {
    color: var( --color-delivered);
  }
  
  .color-cancel {
    color: var(--color-cancel);
  }
  
  
  .cursor-pointer {
    cursor: pointer;
  }

  /* status  */

  .status-waiting {    
    color: var(--color-waiting);
    border: 1px solid var(--color-waiting);
    background-color: rgba(var(--color-waiting), 0.7);
    border-radius: 30px;
    width: 100%;
    padding: 2px 36px;
  
  }
  
  .status-preparing {
    color: var( --color-preparing);
    border: 1px solid var( --color-preparing);
    background-color: rgba(var(--color-preparing), 0.7);
    border-radius: 30px;
    width: 100%;
    padding: 2px 36px;

  }
  
  .status-completed {
    color: var(--color-completed);
    border: 1px solid var(--color-completed);
    background-color: rgba(var(--color-completed), 0.7);
    border-radius: 30px;
    width: 100%;
    padding: 2px 36px;

  }
  
  .status-delivered {
    color: var( --color-delivered);
    border: 1px solid var( --color-delivered);
    background-color: rgba(var(--color-delivered), 0.7);
    border-radius: 30px;
    width: 100%;
    padding: 2px 36px;

  }
  
  .status-cancel {
    color: var(--color-cancel);
    border: 1px solid var(--color-cancel);
    background-color: rgba(var(--color-cancel), 0.7);
    border-radius: 30px;
    width: 100%;
    padding: 2px 36px;

  }

  .cursor-pointer {
    cursor: pointer;
  }
  
  /* custom table */
  .custom-table-container {
    border: 1px solid var(--color-dark-gray);
  }
  
  .mat-mdc-table.custom-table {
    overflow: auto;
    max-height: unset;
    background-color: var(--color-white);
    box-shadow : unset;
  }

  .custom-table p {
    margin: 0px;
    line-height: 1.4;
  }
  
  .custom-table-container .input-search-wrapper,
  .input-search-wrapper {
    border-radius: 6px;
    display: flex;
    align-items: center;
    background: var(--color-white);
    height: 2.3rem;
    padding-left: 8px;
    justify-content: space-between;
  }
  
  .custom-table-container mat-header-row {
    min-height: 60px;
  }
  
  .custom-table-container ._default_button,  ._default_button {
    background: var(--color-white);
    color: var(--color-black);
    border: 1px solid #ebedf3;
    border-radius: 8px;
  }

  .custom-table-container ._primary_button,
  ._primary_button , .swal2-confirm.swal2-styled{
    background: var(--color-primary);
    color: var(--color-white);
    border: 1px solid var(--color-primary);
    border-radius: 8px; 
  }


  .custom-table-container ._secondary_button,
  ._secondary_button , .swal2-cancel.swal2-styled{
    background: var(--color-secondary);
    color: var(--color-white);
    border: 1px solid var(--color-secondary);
    border-radius: 8px; 
  }
  
  .custom-table-container ._success_button,
  ._success_button {
    background: var(--color-lightgreen);
    color: var(--color-white);
    border: 1px solid var(--color-lightgreen);
    border-radius: 8px;
  }
  
  .custom-table-container ._wraper {
    gap: 10px;
  }
  
  .custom-paginator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: white;
    border-radius: 0px 0px 13px 13px;
  }
  
  .page-numbers {
    display: flex;
    gap: 5px;
  }
  
   .page-numbers button {
    font-size: 0.75vw;
    color: var(--color-dark-grayish-blue) !important;
    font-family: var(--font-2-font-family);
    border-radius: 8px;
  }
  
  .page-numbers button.active {
    font-weight: bold;
    color: var(--color-white);
    font-size: 0.75vw;
    color: var(--color-black-russian);
    font-family: var(--font-1-font-family);
    background: var(--color-light-gray);
  
  }

  .custom-table-container .input-search,  .input-search {
    width: 100%;
    border: none;
    height: 28px;
    font-size: 14px;
    font-family: 'Montserrat-Medium';
  }
  

  .custom-table-container .input-search:focus-visible,
  .input-search:focus-visible {
    outline: unset;
  }

  .form-group input {
    height: 56px;
    font-size: 16px;
    font-family: 'Montserrat-Medium';
  }

  .form-group input::placeholder, .input-search::placeholder {
    font-size: 15px;
    font-family: var(--font-2-font-family);
    color: var(--color-cool-gray);
  }

  
  
  input[type="checkbox"] {
    height: auto;
  }

  /* avatar */
  .avatar {
    display: inline-block;
    border-radius: 50%;
    width: var(--avatar-size);
    height: var(--avatar-size);
  }
  
  .custom-table ._column_5 {
    width: 5rem;
    min-width: 5rem;
    max-width: 5rem;
  }
  
  .custom-table ._column_10 {
    width: 10rem;
    min-width: 10rem;
    max-width: 10rem;
  }

  .custom-table ._column_13 {
    width: 13rem;
    min-width: 13rem;
    max-width: 13rem;
  }
  
  .custom-table ._column_15 {
    width: 15rem;
    min-width: 15rem;
    max-width: 15rem;
  }
  
  
  .custom-table ._column_20 {
    width: 20rem;
    min-width: 20rem;
    max-width: 20rem;
  }
  
  .custom-table ._column_25 {
    width: 25rem;
    min-width: 25rem;
    max-width: 25rem;
  }
  
  .custom-table ._column_30 {
    width: 30rem;
    min-width: 30rem;
    max-width: 30rem;
  }
  
  .custom-table ._column_35 {
    width: 35rem;
    min-width: 35rem;
    max-width: 35rem;
  }
  
  .custom-table ._column_40 {
    width: 40rem;
    min-width: 40rem;
    max-width: 40rem;
  }
  
  .custom-table ._column_45 {
    width: 45rem;
    min-width: 45rem;
    max-width: 45rem;
  }
  
  .custom-table ._column_50 {
    width: 50rem;
    min-width: 50rem;
    max-width: 50rem;
  }
  
  .custom-table ._column_55 {
    width: 55rem;
    min-width: 55rem;
    max-width: 55rem;
  }
  
  .custom-table ._column_60 {
    width: 60rem;
    min-width: 60rem;
    max-width: 60rem;
  }
  
  .custom-table ._column_65 {
    width: 65rem;
    min-width: 65rem;
    max-width: 65rem;
  }
  
  
  .custom-table ._column_70 {
    width: 70rem;
    min-width: 70rem;
    max-width: 70rem;
  }
  
  .custom-table ._column_75 {
    width: 75rem;
    min-width: 75rem;
    max-width: 75rem;
  }
  
  
  .custom-table ._column_80 {
    width: 80rem;
    min-width: 80rem;
    max-width: 80rem;
  }
  
  .custom-table ._column_85 {
    width: 85rem;
    min-width: 85rem;
    max-width: 85rem;
  }
  
  
  .custom-table ._column_90 {
    width: 90rem;
    min-width: 90rem;
    max-width: 90rem;
  }
  
  
  .custom-table ._column_95 {
    width: 95rem;
    min-width: 95rem;
    max-width: 95rem;
  }


  ._card {
    background: var(--color-white);
    padding: 20px;
    border-radius: 13px;
  }

  ._bg_icon {
    background-color: #E7E6E2;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 54%;
}

._wraper input#favcolor {
  width: 10px;
  height: 10px;
  border-radius: 48%;
  background: red;
  border: 2px solid red;
  padding: 10px;
}

.mobile-label{
  display: none;
}

._color_round ._content {
  border: 1px solid red;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 58%;
}

.card .form-group input {
  border: 1px solid #DBDADA;
  border-radius: 4px;
  height: 34px;
  
}

.mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {
  cursor: pointer;
  background: var(--color-white);
  border: 1px solid #DBDADA;
  height: 39px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 7px;
}


.mat-mdc-form-field-type-mat-select  .mdc-line-ripple , .card .mat-mdc-form-field-subscript-wrapper{
  display: none;
}

.mat-mdc-form-field{
  width: 100%;
}


.cdk-overlay-pane.mat-mdc-dialog-panel{
  max-width: 100% !important;
}

._main_dashbaord .slick-slider .slick-dots {
  position: absolute;
  top: -40px;
  left: 13%;
  height: 1px;
}

._main_dashbaord .slick-dots li.slick-active button:before{
  font-size: 14px;
}

._main_dashbaord .slick-dots li button:before{
  font-size: 14px;
}


._main_dashbaord .slick-list{
  padding-left: 0px !important;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}


@media (max-width: 1440px) {
  ._login_page{
    height: unset;
  }
}

@media (max-width: 768px) {
  .custom-table .mobile-label{
    display: block;
  }
  ._main_dashbaord .slick-slider .slick-dots {
    top: -28px;
    left: 36%;
  }

  .custom-table mat-header-row {
    display: none;
  }
  
  .custom-table mat-row {
      border: 1px solid gray;
      display: block;
      margin-bottom: 30px;
      
  }

  .custom-table mat-cell {
    display: flex;
    justify-content: space-between;
    text-align: end !important;
  }

  .custom-table ._column_5{
    width: unset;
    max-width: unset;
    min-width: unset;
  }

  .mdc-data-table__row:last-child .mat-mdc-cell {
    border-bottom: 1px solid;
  }

}

@media (max-width: 512px) {
  .form-group {
      margin-bottom: 15px;
  }
}
